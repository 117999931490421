import Control from '@/js/Controls/Control'
import { ViewportDimensions } from '@/js/Helpers/ViewportDimensions'
import naja from 'naja'

class MainMenuControl implements Control {
	private readonly menuBreakpoint = 1024
	private viewport = new ViewportDimensions()
	private menuWrap = document.querySelector<HTMLElement>('.main-menu')
	private currentLevel = 1
	private initializedMobile = false
	private initializedDesktop = false
	private dataLoaded = false

	private readonly jsClasses = {
		expanded: 'js-menu-expanded',
		level: 'js-menu-on-level-',
		jsHide: 'js-hide',
		controlsForward: 'js-menu-control',
		controlsBack: 'js-menu-back',
		dropdownItem: 'js-dropdown-item-'
	} as const

	constructor() {
		window.addEventListener('resize', () => {
			this.initialize()
		})
	}

	private async loadData() {
		if (this.dataLoaded) return

		const url = this.menuWrap?.dataset['load']
		if (!url) return
		await naja.makeRequest('GET', url, null, { history: false })
		this.dataLoaded = true
	}

	private async initializeDesktop() {
		this.setDesktopMenuDropdown() // Set dropdowns first on static data to prevent long delay in UI, run it also on every resize event
		if (this.initializedDesktop) return

		await this.loadData()
		this.setDesktopMenuDropdown()
		this.initializedDesktop = true
	}

	private async initializeMobile() {
		if (this.initializedMobile) return

		this.menuWrap?.addEventListener(
			'click',
			async () => {
				await this.loadData()
				this.addMobileNavigationControls()
			},
			{ once: true }
		)
		this.initializedMobile = true
	}

	public async initialize() {
		if (this.viewport.getViewportWidth() >= this.menuBreakpoint) {
			this.initializeDesktop()
		} else {
			this.initializeMobile()
		}
	}

	private addMobileNavigationControls() {
		const controlsForward = document.querySelectorAll<HTMLElement>(`.${this.jsClasses.controlsForward}`)
		const controlsBack = document.querySelectorAll<HTMLElement>(`.${this.jsClasses.controlsBack}`)

		controlsForward.forEach((forwardLink) => {
			forwardLink.addEventListener('click', (event) => {
				this.moveForward(forwardLink, event)
			})
		})

		controlsBack.forEach((backwardLink) => {
			backwardLink.addEventListener('click', (event) => {
				this.moveBackward(backwardLink, event)
			})
		})
	}

	private setDesktopMenuDropdown() {
		const activeMenuWrap = this.menuWrap?.querySelector<HTMLElement>(
			'.main-menu__item--active .main-menu__list--level-2'
		)
		if (!activeMenuWrap) return

		const items = activeMenuWrap.querySelectorAll<HTMLElement>(
			'.main-menu__item--level-2:not(.main-menu__item--dropdown):not(.main-menu__item--back):not(.main-menu__item--forward)'
		)
		items.forEach((item) => {
			item.classList.remove(this.jsClasses.jsHide)
		})

		const dropdownItems = activeMenuWrap.querySelectorAll('.main-menu__item--dropdown')
		dropdownItems.forEach((item) => {
			item.classList.add(this.jsClasses.jsHide)
		})

		let showDropdown = false
		const wrapWidth = activeMenuWrap.offsetWidth + 1 // + 1 because of rounding
		const dropdownWidth = 70 as const // Magic constant in CSS

		let itemsWidth = 0
		items.forEach((item) => {
			itemsWidth += item.offsetWidth
		})

		let step = items.length - 1 // zero-based index
		while (
			step >= 0 &&
			((!showDropdown && itemsWidth > wrapWidth) || (showDropdown && itemsWidth + dropdownWidth > wrapWidth))
		) {
			itemsWidth -= items[step].offsetWidth
			this.showDropdownItem(items[step])
			showDropdown = true
			step--
		}

		if (showDropdown) {
			dropdownItems[0].classList.remove(this.jsClasses.jsHide)
		}
	}

	private showDropdownItem(element: HTMLElement) {
		element.classList.add(this.jsClasses.jsHide)
		const dropdownItem = element.parentElement?.querySelector(
			`.${this.jsClasses.dropdownItem}${element.dataset.pageid}`
		)

		dropdownItem?.classList.remove(this.jsClasses.jsHide)
	}

	private moveForward(link: Element, event: MouseEvent) {
		if (this.viewport.getViewportWidth() < this.menuBreakpoint) {
			event.preventDefault()

			link.parentElement?.classList.add(this.jsClasses.expanded)

			// Important removeClass/addClass priority
			this.menuWrap?.classList.remove(`${this.jsClasses.level}${this.currentLevel++}`)
			this.menuWrap?.classList.add(`${this.jsClasses.level}${this.currentLevel}`)
		}
	}

	private moveBackward(link: Element, event: MouseEvent) {
		if (this.viewport.getViewportWidth() < this.menuBreakpoint) {
			event.preventDefault()

			// Important removeClass/addClass priority
			this.menuWrap?.classList.remove(`${this.jsClasses.level}${this.currentLevel--}`)
			this.menuWrap?.classList.add(`${this.jsClasses.level}${this.currentLevel}`)

			link.closest(`.${this.jsClasses.expanded}`)?.classList.remove(this.jsClasses.expanded)
		}
	}
}

export default new MainMenuControl()
