import Control from '@/js/Controls/Control'
import Collapsable from 'collapsable.js'
import { CollapsableOptions } from 'collapsable.js/dist/Collapsable'
import { HTMLCollapsableItem } from 'collapsable.js/src/CollapsableItem'
import { DeepPartial } from 'collapsable.js/dist/utils'

type CollapsableGroup = {
	selector: string
	offTapClose: boolean
	options?: DeepPartial<CollapsableOptions>
}

class CollapsableControl implements Control {
	private offTapCloseClass = 'js-collapsable--off-tap-close' as const
	private offTapClose: Collapsable[] = []
	private groups: CollapsableGroup[] = [
		{
			selector: `.js-collapsable:not(.${this.offTapCloseClass})`,
			offTapClose: false
		},
		{
			selector: `.${this.offTapCloseClass}`,
			offTapClose: true,
			options: {
				accordion: true
			}
		}
	]

	public constructor() {
		this.attachHandlers()
	}

	public initialize(context: Element | Document): void {
		this.groups.forEach((group) => {
			const collapsableElements = context.querySelectorAll<HTMLCollapsableItem>(group.selector)

			const collapsable = new Collapsable(collapsableElements, group.options)
			if (group.offTapClose) this.offTapClose.push(collapsable)
		})
	}

	private attachHandlers(): void {
		document.body.addEventListener('click', this.handleOffTapClose.bind(this))
	}

	private handleOffTapClose(event: Event): void {
		const target = event.target
		if (!(target instanceof HTMLElement)) return
		if (target.closest(`.${this.offTapCloseClass}`)) return

		this.offTapClose.forEach((item) => item.collapseAll())
	}
}

export default new CollapsableControl()
